<template>
  <div  id="why-mobile-service-container">
    <v-container>
      <article class="row col-md-8 col-lg-8 col-xl-7 col-xl-6 mx-auto">
        <v-row>
          <v-col cols="12">
            <div class="px-5" id="header-phrase-container">
              <q id="header-phrase">
                "{{$t('WhyMobileServiceSection.Quote')}}"
              </q>
              <p class="information m-md-0 mt-md-4 mb-md-0">-Jessica Anderson, Kiplinger.com  </p>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="col-12">
            <span class="information">{{$t('WhyMobileServiceSection.WhyMobileService')}}</span>
            <h3>
              {{$t('WhyMobileServiceSection.Title')}}
            </h3>
            <div class="feature mb-9">
              <span class="feature-title">
                {{$t('WhyMobileServiceSection.Features[0].TransparencyTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[0].TransparencyDescription')}}
              </p>
            </div>
            <div class="feature mb-9">
              <span class="feature-title">
                 {{$t('WhyMobileServiceSection.Features[1].ServiceWhereYouNeedTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[1].ServiceWhereYouNeedDescription')}}
              </p>
            </div>
            <div class="feature mb-9">
              <span class="feature-title">
                 {{$t('WhyMobileServiceSection.Features[2].NeedPickUpThePhoneTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[2].NeedPickUpThePhoneDescription')}}
              </p>
            </div>
            <div class="feature mb-9">
              <span class="feature-title">
                {{$t('WhyMobileServiceSection.Features[3].WarrantyTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[3].WarrantyDescription')}}
              </p>
            </div>
            <div class="feature mb-9">
              <span class="feature-title">
                 {{$t('WhyMobileServiceSection.Features[4].TransparencyWorkPerformedTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[4].TransparencyWorkPerformedDescription')}}
              </p>
            </div>
            <div class="feature mb-9">
              <span class="feature-title">
                {{$t('WhyMobileServiceSection.Features[5].NoReceiptTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[5].NoReceiptDescription')}}
              </p>
            </div>
            <div class="feature mb-9">
              <span class="feature-title">
                 {{$t('WhyMobileServiceSection.Features[6].SafetySecurityTitle')}}
              </span>
              <p class="feature-description">
                {{$t('WhyMobileServiceSection.Features[6].SafetySecurityDescription')}}
              </p>
            </div>
            <div>
              <p id="end-message">
                {{$t('WhyMobileServiceSection.LastComment')}}
              </p>
            </div>
          </v-col>
        </v-row>
      </article>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/sass/pages/why-mobile-service.scss';
</style>

<script>
export default {
  metaInfo: {
    title: 'UcarMobile: Auto Repair by Best Mobile Car Mechanics',
    meta: [
      {
        name: 'description',
        content: 'Best mobile car repair service near you. Easy and convenient. Reliable and trustworthy mobile auto repair. Get an instant estimate. All makes and models.',
      },
      {
        name: 'keywords',
        content: 'auto repair, mobile mechanic, find local mechanics, mobile car repair, car repair, find mechanic, mechanic, auto shop, auto repair shop, car mechanic',
      },
    ],
  },
};
</script>
