var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "why-mobile-service-container" } },
    [
      _c("v-container", [
        _c(
          "article",
          { staticClass: "row col-md-8 col-lg-8 col-xl-7 col-xl-6 mx-auto" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "px-5",
                      attrs: { id: "header-phrase-container" },
                    },
                    [
                      _c("q", { attrs: { id: "header-phrase" } }, [
                        _vm._v(
                          ' "' +
                            _vm._s(_vm.$t("WhyMobileServiceSection.Quote")) +
                            '" '
                        ),
                      ]),
                      _c(
                        "p",
                        { staticClass: "information m-md-0 mt-md-4 mb-md-0" },
                        [_vm._v("-Jessica Anderson, Kiplinger.com ")]
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mt-15" },
              [
                _c("v-col", { staticClass: "col-12" }, [
                  _c("span", { staticClass: "information" }, [
                    _vm._v(
                      _vm._s(_vm.$t("WhyMobileServiceSection.WhyMobileService"))
                    ),
                  ]),
                  _c("h3", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("WhyMobileServiceSection.Title")) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[0].TransparencyTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[0].TransparencyDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[1].ServiceWhereYouNeedTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[1].ServiceWhereYouNeedDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[2].NeedPickUpThePhoneTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[2].NeedPickUpThePhoneDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[3].WarrantyTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[3].WarrantyDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[4].TransparencyWorkPerformedTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[4].TransparencyWorkPerformedDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[5].NoReceiptTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[5].NoReceiptDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "feature mb-9" }, [
                    _c("span", { staticClass: "feature-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[6].SafetySecurityTitle"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "feature-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "WhyMobileServiceSection.Features[6].SafetySecurityDescription"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("p", { attrs: { id: "end-message" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("WhyMobileServiceSection.LastComment")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }